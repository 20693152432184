





















































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  FundApplyDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";

import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "fundApplyDetail",
  components: {
    AuditBlock,
    ExportWord,
    AttachmentsView,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundMaintainDetail extends Vue {
  dataId?: number;
  detail: FundApplyDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "基金申请详情";

  hostType = AttachmentHostType.FundApply;

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    api.fundApply.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
    });
  }

  // 返回
  private cancel() {
    this.$router.go(-1);
  }

  get auditApi() {
    return {
      canAudit: api.fundApply.canAudit,
      userAudit: api.fundApply.audit
    } as AuditApi
  }



}
